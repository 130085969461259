@import '../../variables.scss';
body{
    line-height: 1.4;
}
.form-control{
    height: 30px !important;
}
xpo-form-footer{
    height: 50px !important;
    background-color: #333;
    bottom: 0;
    display: flex;
    height: 44px;
    justify-content: center;
    position: fixed;
    width: 100%;
}
.mat-mdc-checkbox{
    label{
        font-size: $font-size-small;
    }
}

mat-select.form-control{
    padding: 0;
    display: flex;
    align-items: center;
    font-size: $font-size-medium;
    .mat-mdc-select-trigger{
        padding:0 10px;
    }
    
}
.mat-mdc-option{
    font-size: $font-size-medium;
    min-height: 35px!important;
    &.mat-mdc-option-active{
        .mdc-list-item__primary-text{
            color:$color-actions; 
        }
    }
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, 
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after{
    color:$color-actions;
}
.mat-mdc-select-disabled{
    opacity: 0.7;
    background: #eeeeee!important;
    pointer-events: none;
}
.mat-mdc-checkbox.mat-primary .mdc-checkbox--selected~.mdc-checkbox__ripple, 
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background{
    background-color:$color-actions !important;
    border-color:$color-actions !important;
}
.hazmat_td{
    position: relative;
    a{
        position: absolute;
        right: -5px;
        top:10px;
    }
}
.hazmat_checkbox{
    left: -5px;
    .mdc-checkbox__ripple, .mat-ripple, .mat-mdc-checkbox-touch-target{
        display: none;
    }
}
.order-details__header-total_pallet{
    font-size: $font-size-small;
}
// .ng-trigger-disabledPanels{
//     pointer-events: none;
// }
xpo-dialog-container{
    height: fit-content;
}
::-moz-selection {
    /* Code for Firefox */
    color: $color-white;
    background: $color-actions!important;
}

::selection {
    color: $color-white;
    background: $color-actions!important;
}

// Feedback form css 
.feedback-form {
    .xpo-input-subscript-wrapper {
        bottom: -22px !important;

        xpo-error {
            font-size: $font-size-small;
        }
    }
}
.xpo-snack-bar__content-wrapper{
    text-align: center;
    span{
        padding: 0 10px;
    }
}

xpo-document-view{
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
}
.document-view__extra-document{
    .mdc-checkbox__background{
        border-color: #333333 !important;
    }
}

.mat-mdc-tooltip-panel{
    z-index:10000 !important;
}
xpo-dialog-container, .mat-mdc-dialog-container{
    width: 450px !important;
}
.match_modal .mat-mdc-dialog-container, .select_commodities .mat-mdc-dialog-container{
    width: 1050px !important;
}
.ziptozip_modal .mat-mdc-dialog-container, .commodity_weight_modal .mat-mdc-dialog-container{
    width: 666px !important;
}

.mat-mdc-form-field-error{
    color: $color-errors;
}
.pointer-events-all{
    pointer-events: all;
}
.f-size-12{
    font-size: $font-size-small;
}

// card css for lib 
.xpo-Card-header {
    height: 44px;
    padding: 10px 15px !important;
    background-color: #f5f5f5 !important;
}
.xpo-Card-title {
    font-size: $font-size-large !important;
    text-transform: none;
}
.xpo-Card-container {
    height: 100% !important;
}

xpo-form-header{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.13);
    background-color: #ffffff;
    display: flex;
    height: 44px;
    position: fixed;
    width: 100%;
    z-index: 1001;
}

.xpo-dialog-container, .mat-mdc-dialog-container{
    .xpo-Card-content{
        padding: 30px 15px;
    }
    .xpo-Card-title{
        font-size: $font-size-medium !important;
        font-weight:$font-weight-bold;
    }
    .xpo-Card-actions{
        min-height: 44px;
        padding: 10px 15px;
        border-top: 1px solid #dfdfdf;
        display: block;
        .xpo-ButtonGroup{
           .mat-stroked-button{
            margin-left: auto;
           }
        }
    }
}

.order-header-style{
    .xpo-Card-container{
        overflow: visible;
    }
}

// Button css 
.xpo-Link--regular{
    font-size: $font-size-small;
    color: $color-text-gray;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    padding: 5px 8px;
    &.right-none{
      padding-right: 0;
    }
    &.right-15{
      padding-right: 15px;
    }
    &.left-none{
        padding-left: 0;
    }
    &.padding-none{
        padding: 0;
    }
    &:hover{
        text-decoration: none;
    }
    &.action{
        color: $color-actions;
        &:hover{
            color: $color-text-gray;
        }
    }
    &[disabled]{
        color: $color-text-disabled;
        pointer-events: none;
    }
}
.fw{
    &-bold{
        font-weight: bold;
    }
}

button{
    &:hover{
        cursor: pointer;
    }
}
.xpo-ActionButton{
    color: $color-actions;
    border:none;
    background-color: transparent;
    &:hover{
        color: $color-text-gray;
    }
    &[disabled]{
        color: $color-text-disabled;
        pointer-events: none;
    }
}
.hazmat-details__card{
    display: block !important;
    .xpo-Card-container{
       overflow: visible !important;
    }
}

.xpo-flex-stretch{
    flex: 1;
}
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic{
    stroke: $color-white !important;

}
.mat-mdc-progress-spinner{
    height: 60px !important;
    width: auto !important;
}

.bill-entry--read-only {
    .mdc-checkbox__background {
        border-color: transparent !important;
        background-color: var(--mdc-checkbox-disabled-selected-icon-color, rgba(0, 0, 0, 0.38)) !important;
    }

    .checkbox-should-enable {
        .mdc-checkbox__background {
            border-color: var(--mdc-checkbox-unselected-icon-color, rgba(0, 0, 0, 0.54)) !important;
            background-color: transparent !important;
        }
    }
}
.font-size-small{
    font-size: $font-size-small;
}
.fieldset-disabled{
    .delete-button, .line-item-duplicate, .delete-action, .mat-mdc-checkbox{
        color: #bdbdbd !important;
        pointer-events: none;
    }
}
.checkbox-should-enable{
    .mat-mdc-checkbox.mat-mdc-checkbox-disabled{
        .mdc-checkbox__background{
            background-color: var(--mdc-checkbox-disabled-selected-icon-color, rgba(0, 0, 0, 0.38)) !important;
            border-color: transparent !important;
        }
    }
}
.mexico-warning{
    padding:10px 15px;
}
  